<template>
	<div class="lf_match_top_box">
		<div class="tit">
			<span class="name">{{ match!=undefined&&match.mt_id!=undefined?match.mt_cn_name:"" }}</span>
			<span class="name">{{ matchhole.cr_cn_name }}</span>
		</div>
		<div class="lf_place_time">
			<span class="time">
				{{ match.mt_date }}
			</span>

		</div>
		<div class="lf_rd_change">
			<div class="r">
				<!--根据需求进入排行榜页面就定位到当前轮-->
				<a href="javascript:void(0)" v-for="(i,index) in roundarr" @click="changeround(i)" class="hovers">R{{i}} <div :class="(i==rd_id?'lf_bb_border':'')"></div></a>
			</div>
			<div class="lf_pos_box_1322">
				<!-- <div class="lf_pos_type_box">
        					<div id="gp_type">name</div>
        				</div> -->
				<div class="lf_pos_type_box">
					<div @click="showmatchmode">{{ langs[matchmode] }}</div>
					<img @click="showmatchmode" v-if="showmatchmode_status&&matchmodelist!=undefined&&matchmodelist.length!=undefined&&matchmodelist.length>1" src="../../src/assets/images/u2766.png" alt="">
					<div class="lf_pos_type_box_con" v-if="showmatchmode_status&&matchmodelist!=undefined&&matchmodelist.length!=undefined&&matchmodelist.length>1">
						<!-- <div class="lf_pos_type_box_con1 ">name</div> -->
						<template v-for="(item,index) in matchmodelist">
							<div class="lf_pos_type_box_con1" :class="item.value==matchmode?'lf_pos_type_box_con1_select':''" @click="changematchmode(item.value,item.nttype,item.gptype)">{{item.label}}</div>
						</template>
					</div>
				</div>
			</div>
		</div>

	</div>

	<!-- 组别按钮 开始 -->

	<div class="teams_f" v-if="glist!=undefined&&glist.length!=undefined&&glist.length>0">
		<ul>
			<li class="li_s lsgroup" :class="(gid==item.gid?'hovers':'')+' '+(item.gtype=='P'?'lsgroup_P':'lsgroup_T')" v-for="item in glist" @click="changegid(item.gid)">{{ item.gname }}</li>
		</ul>
		<div class="btn"><img src="../../src/assets/images/u2767.png" alt="" /></div>
	</div>
	<!-- 组别按钮 结束 -->

	<!-- 加洞 -->
	<div class="lf_add_hole_box" v-if="match.playoffhole==1">
		<div class="lf_add_hole_box_1" style="border: none;">
			<div class="lf_add_hole_box_1_1">{{langs.playoff}}</div>
			<div class="lf_add_hole_box_2_1" style="justify-content: center;">{{ langs.text_player }}</div>
			<div class="lf_add_hole_box_3_1" v-for="(b,index) in playoff">
				<div class="lf_add_hole_nums">#{{index+1}}</div>
				<div class="lf_hole_info">(H{{ b.ho_id }})</div>
			</div>
		</div>
		<div v-for="(item,index) in playoff[0]['score']" class="lf_add_hole_box_2 lf_add_hole_box_1_win_box">
			<div class="lf_add_hole_box_1_2 " :class="item.result=='WNR'?'lf_add_hole_box_1_win':'lf_add_hole_box_1_elm'">
				<div>{{ item.result }}</div>
			</div>
			<div class="lf_add_hole_box_2_2 ">
				<div class="lf_country">
					<img v-if="match_set.mt_country_show_type==1" class="lf_country_img" :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+ (item.player!=undefined&&item.player.pl_country?item.player.pl_country:'country')+'.png'" alt="">
					<span v-if="match_set.mt_country_show_type==2" class="lf_country_text">{{item.player!=undefined&&item.player.pl_country?item.player.pl_country:''}}</span>
				</div>
				<div class="lf_add_pl_name">{{ item.player!=undefined?item.player.pl_cn_name:"" }}{{ match_set.mt_amateur=='(A)'&&item.player.pl_type=='AMA'?'(A)':'' }}</div>
			</div>
			<div class="lf_add_hole_box_3_2" v-for="playerscore in playoff">{{playerscore.score[index].score}}</div>
		</div>

		<!-- <div class="lf_add_hole_box_2 lf_add_hole_box_1_win_box">
			<div class="lf_add_hole_box_1_2 ">
				<div>WNR</div>
			</div>
			<div class="lf_add_hole_box_2_2 ">
				<div class="lf_country">pl_country</div>
				<div class="lf_add_pl_name">pl_cn_name</div>
			</div>
			<div class="lf_add_hole_box_3_2">score</div>
		</div> -->
	</div>
</template>

<script>
import axiosx from '../axios/postx';
import axios from '../axios/request';
import { getSystemLang, getLangAll } from "../lang";
export default {
	data() {
		return {
			langs: '',
			systemlang: 'cn',
			roundarr:[],
			showmatchmode_status:false,
			rd_id:String
		}
	},
	name: 'Btn',
	props: {
		msg: String,
		flag: String,
		cd: String,//菜单
		undergoing: String,
		year: String,
		match: Object,
		match_set: Object,
		modal: String,
		playoff: Object,
		matchhole: Object,
		listmode: String,
		rd_id: String,
	},
	watch:{
		match(newVal,oldVal){
			this.match.mt_date = this.datetostyle(this.match.mt_start_date)+(this.match.mt_start_date==this.match.mt_end_date?'':(' ~ '+this.datetostyle(this.match.mt_end_date)));
			if(this.rd_id==''||this.rd_id==undefined){
				this.rd_id = newVal.mt_round;
			}
			// console.log(newVal.mt_round,this.rd_id)
			var roundarr = [];
			for(var i=1;i<=newVal.mt_round_num;i++){
				roundarr.push(i);
			}
			this.roundarr = roundarr;
			this.setmatchclass();
		},
		listmode(newVal,oldVal){
			// console.log(newVal)
			this.setmatchclass();
		}
	},
	created() {
		this.btnHeight = document.documentElement.clientHeight + 'px';
		console.log(this.btnHeight);
		this.systemlang = getSystemLang();
		this.langs = getLangAll(this.systemlang);
		this.numarr = ["0", this.langs.yi, this.langs.er, this.langs.san, this.langs.si, this.langs.wu]
		var rd_id = this.rd_id;
		this.bygroup = this.$route.query.bygroup == '' || this.$route.query.bygroup == undefined ? 0 : parseInt(this.$route.query.bygroup);
		var params = this.$route.query;
		var g = params.g==undefined?(params.gpid==undefined?"":params.gpid):params.g;
		this.gid = g;
		if (this.gid == '0') {
			if (this.listmode == 'BGS' || this.listmode == 'BDS' || this.listmode == 'DFS' || this.listmode == 'NNP' || this.listmode == 'ZBCD') {
				this.gid = 'P';
			} else {
				this.gid = 'T';
			}
		}
		let currentUrl = window.location.href;
		currentUrl = encodeURIComponent(currentUrl);
		var roundarr = [];
		for (var i = 1; i <= this.match.mt_round_num; i++) {
			roundarr.push(i);
		}
		this.roundarr = roundarr;
		this.setmatchclass();
	},
	mounted() {
		document.body.style.overflow = "visible";
	},
	methods: {
		datetostyle(date){
			date = new Date(date);
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const weekday = date.getDay();
			if(this.systemlang == "en"){
				var arr =["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
			}else{
				var arr = ['日','一','二','三','四','五','六'];
			}
			return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}(${arr[weekday]})`;
		},
		changeround(round){
			if(round>this.match.mt_round){
				return;
			}
			let routeUrl = this.$router.resolve({
				path: "/",
				query: {
					lang:this.systemlang,
					MatchID:this.match.mt_id,
					RoundID:round,
					s:'match'
				}
			});
			window.open(routeUrl.href, '_self');
		},
		setmatchclass(){
			var glist=[];
			var matchmodelist = [];
			var matchclass = this.match.class;
			var nttype = 'T';
			if(this.match_set.match_per==1||this.match_set.match_tm==0){
				nttype = 'T';
				if(this.match_set.mt_scoring_mode=='NNP'||this.match_set.mt_scoring_mode=='ZBCD'||this.match_set.mt_scoring_mode=='NNP_ZBCD'){
					matchmodelist.push({value:"BGS",label:this.langs.BGS,nttype:'T',gptype:'P'})
					nttype = 'N';
				}
				var str = "";
				str = this.langs[this.match_set.mt_scoring_mode]
				matchmodelist.push({value:this.match_set.mt_scoring_mode,label:str,nttype:nttype,gptype:'P'})
				for(var k in matchclass){
					if(matchclass[k].gtype=='P'){
						matchclass[k]["gid"] = matchclass[k]["gid"]+'';
						// matchclass[k]["gname"] = this.langs.gerensai+'---'+matchclass[k]["gname"];
						matchclass[k]["gname"] = matchclass[k]["gname"];
						if(this.$route.query.list=="TDDF"){
							
						}else{
							glist.push(matchclass[k]);
						}
					}else{
						continue;
					}
				}
			}
			if(this.match_set.match_tm==1){
				nttype = 'T';
				var str = "";
				str = this.langs[this.match_set.score_type]
				matchmodelist.push({value:this.match_set.score_type,label:str,nttype:nttype,gptype:'T'})
				for(var k in matchclass){
					if(matchclass[k].gtype=='T'){
						matchclass[k]["gid"] = matchclass[k]["gid"]+'';
						// matchclass[k]["gname"] = this.langs.tuanduisai+'---'+matchclass[k]["gname"];
						matchclass[k]["gname"] = matchclass[k]["gname"];
						if(this.$route.query.list=="TDDF"){
							glist.push(matchclass[k]);
						}else{
							
						}
					}else{
						continue;
					}
				}
			}
			console.log("matchmodelist",matchmodelist,glist,this.$route.query.list)
			this.matchmodelist = matchmodelist;
			if(matchmodelist.length==0){
				return;
			}
			var inmatchmodelist = 0;
			var curlistmode = this.listmode;
			if((curlistmode == "NNP"||curlistmode == "NNP_ZBCD")&&this.modal!='NetScore'){
				curlistmode = "BGS";
			}
			for(var k in matchmodelist){
				if(matchmodelist[k].value == curlistmode){
					inmatchmodelist = 1;
					break;
				}
			}
			if(curlistmode==undefined||inmatchmodelist==0){
				this.matchmode = matchmodelist[0].value;
			}else{
				this.matchmode = curlistmode;
			}
			this.glist = glist;
			if(glist.length>0&&(this.gid==undefined||this.gid=='')){
				this.gid = glist[0]['gid'];
			}
		},
		changegid(e){
			var params = this.$route.query;
			if(e=='P'){
				params["g"] = "";
				params.list = this.match_set.mt_scoring_mode;
			}else if(e=='T'){
				params["g"] = "";
				params.list = this.match_set.score_type;
			}else{
				for(var k in this.glist){
					if(this.glist[k].gid == e){
						params["g"] = e;
						if(this.glist[k].gtype == "P"){
							params.list = this.match_set.mt_scoring_mode;
						}else if(this.glist[k].gtype == "T"){
							params.list = this.match_set.score_type;
						}
					}
				}
			}
			let routeUrl = this.$router.resolve({
				path: "/",
				query: params
			});
			window.open(routeUrl.href, '_self');
		},
		changematchmode(matchmode,nttype,gptype){
			var params = this.$route.query;
			params["list"] = matchmode;
			params["g"] = "";
			if(nttype == "N"&&params["modal"]!="NetScore"){
				params["modal"]="NetScore";
			}else if(nttype != "N"&&params["modal"]=="NetScore"){
				params["modal"]="";
			}
			if(this.glist.length>0){
				if(matchmode==this.match_set.score_type){
					var gtype = "T";
				}else if(matchmode==this.match_set.mt_scoring_mode){
					var gtype = "P";
				}
				for(var k in this.match.class){
					if(this.match.class[k].gtype==gtype){
						params["g"] = this.match.class[k].gid;
						break;
					}
				}
			}
			let routeUrl = this.$router.resolve({
				path: "/",
				query: params
			});
			window.open(routeUrl.href, '_self');
		},
		showmatchmode(){
			this.showmatchmode_status = !this.showmatchmode_status
		}
	}
}
</script>
<style>
html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lf_match_top_box {
	width: 7rem;
	background-color: rgba(255, 255, 255, 1);
	border: none;
	border-radius: 0.1rem;
	margin: 0.25rem auto 0;
	padding-bottom: 0.2rem;
}

.lf_match_top_box .tit .name {
	width: 100%;
	height: 0.66rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	font-weight: bold;
	font-size: 0.3rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lf_rd_change {
	width: 6.7rem;
	height: 0.7rem;
	background: inherit;
	background-color: rgba(240, 243, 246, 1);
	border: none;
	border-radius: 0.1rem;
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.lf_country_img {
	width: 0.5rem;
}

.lf_mp_logo {
	width: 0.8rem;
	height: 0.6rem;
}

.lf_place_time {
	justify-content: center;
	height: 0.36rem;
	display: flex;
	align-items: center;
	padding: 0 8px;
	background-color: #fff;
}

.lf_place_time .time {
	font-size: 0.24rem;
	color: #999999;
}

.lf_pos_type_box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.1rem;
	height: 0.4rem;
	font-size: 0.26rem;
	color: #025287;
	border-left: 1px solid #025287;
}

.lf_pos_type_box img {
	width: 0.2rem;
	height: 0.2rem;
	margin-left: 0.1rem;
}

.lf_pos_type_box_con {
	position: absolute;
	left: 0.05rem;
	top: 0.5rem;
	width: 2rem;
	height: 1rem;
	z-index: 99;
}

.lf_pos_type_box_con1 {
	width: 2rem;
	height: 0.5rem;
	background-color: #fff;
	color: #025287;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	font-size: 0.26rem;
	border: 1px solid #025287;
}

.lf_pos_type_box_con1:nth-of-type(n+2) {
	border-top: none;
}

.lf_pos_type_box_con1_select {
	background-color: #025287;
	color: #fff;
}

.r a {
	width: 0.7rem;
	height: 0.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.32rem;
	padding: 0;
	flex-shrink: 0;
	position: relative;
}

.r {
	display: flex;
	flex-grow: 1;
	overflow-x: scroll;
	margin: 0 0.2rem;
	height: 0.7rem;
	background-color: rgba(240, 243, 246, 1);
	border-radius: 0.1rem;
}

.hovers {
	font-weight: 700;
	font-size: 0.4rem;
	color: #025287;
}

.hovers .lf_bb_border {
	display: block;
	width: 0.3rem;
	height: 0.08rem;
	background: inherit;
	background-color: rgba(2, 82, 135, 1);
	border: none;
	border-radius: 0.05rem;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	position: absolute;
	left: 0.2rem;
	bottom: 0;
}

.lf_add_hole_box {
	width: 7rem;
	display: flex;
	flex-direction: column;
	overflow-x: scroll;
	background-color: #fff;
	margin: 0 auto;
	border-radius: 0.1rem;
}

.lf_add_hole_box_1 {
	min-width: 100%;
	display: flex;
	border-top: 0.1rem solid #eeeeee;
	background-color: #015289;
	flex-grow: 1;
	width: fit-content;
	align-items: center;
}

.lf_add_hole_box_1_1 {
	width: 1.5rem;
	height: 0.5rem;
	display: flex;
	align-items: center;
	text-indent: 0.1rem;
	color: #fff;
	font-size: 0.24rem;
	flex-shrink: 0;
}

.lf_add_hole_box_1_2 {
	width: 1.5rem;
	height: 0.4rem;
	font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
	font-weight: 700;
	font-style: normal;
	font-size: 0.24rem;
	display: flex;
	align-items: center;
	margin: 0.05rem 0 0.05rem 0;
}

.lf_add_hole_box_1_win div {
	width: 1rem;
	height: 0.4rem;
	background: inherit;
	background-color: #CB0A3D;
	border: none;
	border-radius: 0.24rem;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
	font-weight: 700;
	font-style: normal;
	font-size: 0.24rem;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lf_add_hole_box_1_elm div {
	width: 1rem;
	height: 0.4rem;
	background: inherit;
	background-color: rgba(248, 248, 248, 1);
	border: none;
	border-radius: 0.24rem;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
	font-weight: 700;
	font-style: normal;
	font-size: 0.24rem;
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lf_add_hole_box_2 {
	min-width: 100%;
	height: 0.6rem;
	display: flex;
	width: fit-content;
}

.lf_add_hole_box_2_1 {
	width: 2rem;
	height: 0.6rem;
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 0.24rem;
	flex-shrink: 0;
}

.lf_add_hole_box_2_2 {
	display: flex;
	align-items: center;
	width: 2rem;
	height: 0.6rem;
	font-size: 0.3rem;
	color: #333;
	overflow: hidden;
}

.lf_add_hole_box_2_3 {
	display: flex;
	align-items: center;
	width: 2rem;
	height: 0.6rem;
	font-size: 0.22rem;
	color: #333;
	overflow: hidden;
}

.lf_country {
	font-size: 0.24rem;
	color: #cc003d;
	margin-right: 0.1rem;
	position: relative;
	top: 0.02rem;
}

.lf_add_pl_name {
	font-weight: bold;
}

.lf_add_hole_box_3 {
	height: 1.5rem;
	display: flex;
	width: fit-content;
	min-width: 100%;
}

.lf_add_hole_box_3_1 {
	width: 1.4rem;
	height: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-shrink: 0;
}

.lf_add_hole_nums {
	color: #fff;
	font-size: 0.24rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lf_hole_info {
	color: #fff;
	font-size: 0.18rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lf_add_hole_box_3_2,
.lf_add_hole_box_3_3 {
	width: 1.4rem;
	color: #333;
	font-size: 0.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 0.5rem;
}

.teams_f .btn {
	height: 0.55rem;
	width: 0.35rem;
	margin: 0px 2px 0 6px;
	padding: 0;
	font-size: 14px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.1rem;
	flex-shrink: 0;
}

.teams_f .btn img {
	float: left;
	width: 0.3rem;
	height: 0.3rem;
	transform: rotate(-90deg);
}

.teams_f {
	width: 7rem;
	margin: 0 auto;
	overflow: hidden;
	height: 0.85rem;
	display: flex;
	align-items: center;
}

.teams_f ul {
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	align-items: center;
	height: 0.55rem;
	overflow-x: scroll;
}

.teams_f li {
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #fff;
	word-break: keep-all;
	padding: 0 0.1rem;
	height: 0.55rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.24rem;
	margin-left: 0.1rem;
	border-radius: 0.1rem;
	color: #999999;
}

.teams_f ul .hovers {
	color: #025287;
	border: 1px solid #025287;
}

@media screen and (max-width: 768px) {}
</style>
